import { Controller } from "@hotwired/stimulus";

/*
 * Sólo permite enviar el formulario de contacto después de unos
 * segundos, para evitar el spam.
 */
export default class extends Controller {
  static targets = ["submit", "thanks", "form"];
  static values = {
    delay: {
      type: Number,
      default: 60,
    },
    thanks: String,
    retry: String,
  };

  connect() {
    if (!this.hasSubmitTarget) return;

    this.formdataEvent = this._formdataEvent.bind(this);

    this.formTarget.addEventListener("formdata", this.formdataEvent);

    this.submitTarget.disabled = true;

    this._value = this.submitTarget.value;

    // Esperar un minuto desde que se carga la página hasta que se
    // permite enviar.
    this._interval = setInterval(() => {
      const delay = this.delayValue;

      if (delay == 0) {
        clearInterval(this._interval);
        this.submitTarget.disabled = false;
        this.submitTarget.value = this._value;
      } else {
        this.delayValue = delay - 1;
        this.submitTarget.value = `${this._value} (${this.delayValue})`;
      }
    }, 1000);
  }

  disconnect() {
    this.formTarget.removeEventListener("formdata", this.formdataEvent);
  }

  _formdataEvent(event) {
    this.contact(event.formData);
  }

  set disabled(state) {
    this.formTarget.elements.forEach((x) => (x.disabled = !!state));
  }

  submit(event) {
    event.preventDefault();

    if ("FormDataEvent" in window) {
      new FormData(event.target);
    } else {
      this.contact(new FormData(event.target));
    }
  }

  async contact(formData) {
    this.disabled = true;

    const form = this.formTarget;
    const method = form.method;
    const body = formData;
    const mode = "no-cors";
    const credentials = "include";

    try {
      const response = await fetch(form.action, {
        method,
        body,
        mode,
        credentials,
      });

      this.thanksTarget.innerHTML = `<p>${
        response.status >= 400 ? this.retryValue : this.thanksValue
      }</p>`;
    } catch (e) {
      this.thanksTarget.innerHTML = `<p>${this.retryValue}</p>`;
    }

    this.disabled = false;
  }
}
