import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "clientName",
    "clientVersion",
    "osName",
    "osVersion",
    "viewportWidth",
    "viewportHeight",
    "devicePixelRatio",
    "deviceType",
  ];

  connect() {
    this.element.style.zIndex = 10000;
    this.resizeEvent = this._resizeEvent.bind(this);

    this.clientNameTarget.innerText = window.device.client.name;
    this.clientVersionTarget.innerText = window.device.client.version;
    this.osNameTarget.innerText = window.device.os.name;
    this.osVersionTarget.innerText = window.device.os.version;
    this.deviceTypeTarget.innerText = window.device.device.type;

    this.viewportWidthTarget.innerText = window.innerWidth;
    this.viewportHeightTarget.innerText = window.innerHeight;
    this.devicePixelRatioTarget.innerText = window.devicePixelRatio;

    window.addEventListener("resize", this.resizeEvent);
  }

  disconnect() {
    window.removeEventListener("resize", this.resizeEvent);
  }

  _resizeEvent(event) {
    this.viewportWidthTarget.innerText = window.innerWidth;
    this.viewportHeightTarget.innerText = window.innerHeight;
  }
}
