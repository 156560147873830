import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];
  static classes = ["up", "down", "top"];
  static values = {
    scroll: { type: Number, default: 0 },
    ticking: { type: Boolean, default: false },
    threshold: { type: Number, default: 5 },
  };

  connect() {
    this._event = this._event.bind(this);
    this.scrollValue = window.scrollY;

    document.addEventListener("scroll", this._event);
  }

  disconnect() {
    document.removeEventListener("scroll", this._event);
  }

  get direction() {
    return this.scrollValue > window.scrollY ? "up" : "down";
  }

  get threshold() {
    return (window.scrollY - this.scrollValue);
  }

  _event(event) {
    const direction = this.direction;
    const scrollValue = window.scrollY;

    if (!this.tickingValue) {
      window.requestAnimationFrame(() => {
        if (direction === "up") {
          if (this.scrollValue === 0) {
            this.element.classList.remove(...this.upClasses);
            this.element.classList.remove(...this.downClasses);
            this.element.classList.add(...this.topClasses);
          } else {
            this.element.classList.add(...this.upClasses);
            this.element.classList.remove(...this.downClasses);
            this.element.classList.remove(...this.topClasses);
          }
        } else {
          if (this.threshold > this.thresholdValue) {
            this.element.classList.remove(...this.topClasses);
            this.element.classList.remove(...this.upClasses);
            this.element.classList.add(...this.downClasses);
          }
        }

        this.tickingValue = false;
        this.scrollValue = scrollValue;
      });

      this.tickingValue = true;
    }
  }
}
